<template>
  <div>
    <div class="qchat-attachment">
      <div class="qcentered-item">
        <div
          class="qchat-attachment__panel"
          :class="[
            { expanded: expandedPanel },
            { full: fileAttachments.length > 2 && expandedPanel }
          ]"
        >
          <section v-if="fileAttachments.length" name="Header" class="q-mb-2">
            <div
              class="q-d-flex q-justify-content-between"
              @click="expandedPanel = !expandedPanel"
            >
              <div class="qcentered-item">
                <Icons
                  class="q-mr-2"
                  :key="`icon-file-${fileAttachments.length}`"
                  :type="getUploadStatus"
                  :fill="getUploadStatusColor()"
                  size="14"
                />
                {{ fileStatus.success }} of
                {{ fileAttachments.length }} Uploaded File
              </div>
              <div class="q-float-right">
                <Icons
                  size="14"
                  :type="expandedPanel ? 'la-angle-up' : 'la-angle-down'"
                />
              </div>
            </div>
          </section>
          <section
            v-if="expandedPanel"
            name="Content"
            class="q-row qchat-attachment__panel__list"
            :class="{ full: fileAttachments.length > 2 && expandedPanel }"
          >
            <div
              v-for="(file, idx) in fileAttachments"
              :key="file.id"
              class="qchat-attachment__file"
              :class="getColumnClass(idx)"
              @mouseover="hoverIdx = idx"
              @mouseleave="hoverIdx = -1"
            >
              <div>
                <div class="qchat-attachment__master qcentered-item">
                  <img
                    v-if="file.type === 'image'"
                    :src="setImageContent(file)"
                    class="qchat-attachment__master__preview"
                  />
                  <Icons
                    v-else
                    :type="getAttachmentIcon(file.type)"
                    size="48"
                  />
                  <div
                    :class="`qchat-attachment__master__status ${file.status}`"
                  >
                    <Icons
                      :key="`attachment-status-${idx}`"
                      :type="getAttachmentUploadStatus(file.status)"
                      size="13"
                    />
                  </div>
                  <div
                    v-if="idx === hoverIdx"
                    :class="`qchat-attachment__master__delete`"
                    @click="deleteAttachment(idx)"
                  >
                    <Icons type="la-trash-alt" size="14" />
                  </div>
                  <!-- <div :class="`qchat-attachment__master__caption`">
                  <i class="las la-edit"></i>
                  Caption
                </div> -->
                </div>
                <p class="qchat-attachment__filename q-mb-0 q-mt-1 q-tooltip">
                  <span class="q-tooltiptext">
                    {{ file.file.name }}
                  </span>
                  {{ limitFilename(file.file.name, idx) }}
                </p>
              </div>
            </div>
          </section>
          <section v-if="expandedPanel" name="Submit Button">
            <button
              class="q-btn qchat-attachment__submit"
              :disabled="fileStatus.pending > 0"
              @click="sendAttachmentMessage"
              :style="styles.generalButton"
            >
              Send Attachment
            </button>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
const Icons = () => import("../../Icons/index.vue");

export default {
  components: {
    Icons
  },
  data() {
    return {
      expandedPanel: false,
      hoverIdx: -1
    };
  },
  computed: {
    ...mapState("messenger", ["fileAttachments", "user", "styles"]),
    fileStatus() {
      const files = this.fileAttachments;
      const status = {
        success: 0,
        failed: 0,
        pending: 0
      };
      if (files.length) {
        files.forEach(file => {
          status[file.status]++;
        });
        return status;
      } else return status;
    },
    getUploadStatus() {
      if (this.fileStatus.success === this.fileAttachments.length)
        return this.getAttachmentUploadStatus("success");
      else if (this.fileStatus.pending > 0)
        return this.getAttachmentUploadStatus("pending");
      else if (this.fileStatus.failed === this.fileAttachments.length)
        return this.getAttachmentUploadStatus("failed");
      else if (this.fileStatus.success > 0 && this.fileStatus.failed > 0)
        return this.getAttachmentUploadStatus("warning");
      else return "";
    }
  },
  watch: {
    fileAttachments() {
      if (!this.fileAttachments.length) this.expandedPanel = false;
      else this.expandedPanel = true;
    }
  },
  methods: {
    setImageContent(file) {
      if (file.status) return URL.createObjectURL(file.file);
      else return file.url;
    },
    getColumnClass(idx) {
      if (this.fileAttachments.length - 1 === idx && idx % 2 == 0) {
        return "col";
      } else {
        if (idx % 2 == 0) return "col-6 pr-1";
        else return "col-6 pl-1";
      }
    },
    limitFilename(name, idx) {
      const limit =
        this.fileAttachments.length - 1 === idx && idx % 2 == 0 ? 43 : 23;
      return name.length >= limit ? name.substring(0, limit) + "..." : name;
    },
    deleteAttachment(idx) {
      this.$store.commit("messenger/DELETE_ATTACHMENT", idx);
    },
    getUploadStatusColor() {
      if (this.fileStatus.success === this.fileAttachments.length)
        return "#28a745";
      else if (this.fileStatus.failed === this.fileAttachments.length)
        return "#eb374d";
      else if (this.fileStatus.success > 0 && this.fileStatus.failed > 0)
        return "#ffc107";
      else return "currentColor";
    },
    getAttachmentUploadStatus(status) {
      switch (status) {
        case "success":
          return "la-check";
        case "pending":
          return "la-clock";
        case "failed":
          return "la-exclamation";
        case "warning":
          return "la-exclamation";
        default:
          return "";
      }
    },
    async sendAttachmentMessage() {
      await this.fileAttachments.forEach(file => {
        this.$store.dispatch("conversation/sendMessage", {
          user: this.user,
          payload: {
            type: file.type,
            file_url: file.url,
            room_id: localStorage.getItem("qchat_room_id")
          },
          file
        });
      });
      this.expandedPanel = false;
      this.$store.commit("messenger/DELETE_ALL_ATTACHMENT");
    },
    getAttachmentIcon(type) {
      switch (type) {
        case "document":
          return "la-file-alt";
        case "video":
          return "la-file-video";
        case "audio":
          return "la-file-audio";
      }
    }
  }
};
</script>
